import React from "react";
import Layout from "../components/layout";
import ProjectComponent from "../components/project-component";
import { graphql } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import SEO from "../components/seo";

const IndexPage = ({ data, location }) => {
  let thumbData = [];

  data.contentfulListOfProjects.index.forEach((e) => {
    return e.thumbnails.forEach((t) => {
      thumbData.push({
        thumbnail: t,
        title: e.title,
        color: e.color,
        type: e.type,
        year: e.year,
        slug: e.slug,
      });
    });
  });

  const seo = {
    title: "The Portfolio of Claire Pedersen",
    description: "Claire Pedersen is a Brooklyn based designer specialized in brand identity, print design, packaging design and web design.",
    image: data.contentfulListOfProjects.seo.image
  }

  return (
    <>
      <SEO meta={seo} pathname={""} />
      <div id="top"></div>
      <Layout>
        {data.contentfulListOfProjects.index.map((e, i) => (
          <ProjectComponent project={e} key={`project-${i}`} />
        ))}
        <div onClick={() => scrollTo("#top")} className="cursor-pointer leading-tight mb-5 inline-block text-base sm:text-xl ">
          <img src="/arrow-up.svg"  className="block mb-2 w-5 md:w-6" />
          <span className="uppercase leading-none">Back<br/>to Top</span>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    contentfulListOfProjects(
      id: { eq: "b67429a5-fc50-53c9-9296-95ea21f5822e" }
    ) {
      seo {
        title
        description
        image {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      index {
        id
        title
        credits
        year
        slug
        color
        type
        media {
          file {
            contentType
            url
            fileName
          }
          fluid(maxWidth: 1700, quality: 100) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        thumbnails {
          fluid(maxWidth: 1700, quality: 100) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        description {
          raw
        }
      }
    }
  }
`;

export default IndexPage;
